const initLanguageForms = function(){
    const languageForms = document.querySelectorAll('.form-language');

    languageForms.forEach(form => {
        let buttons = form.querySelectorAll('button');

        buttons.forEach(button => {
            button.addEventListener('click', function() {        
                if (!this.classList.contains('active')) {
                    buttons.forEach(btn => btn.classList.remove('active'));
                    this.classList.add('active');

                    const codeInput = form.querySelector('input[name="code"]');
                    codeInput.value = this.getAttribute('data-lang-code');
                    form.submit();
                }
            });
        });
    });
};

export default initLanguageForms;