// import 'bootstrap/dist/css/bootstrap.css';
import './scss/bootstrap.scss';
// Import our custom CSS
// import './scss/styles.scss';

// import $ from 'jquery';
// define & and jQuery on the global window object

// Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap';

// Import just what we need
// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

import initLanguageForms from './language';
import initSearch from './search';

// Object.assign(window, { jQuery: $, $ });
// window.$ = window.jQuery = $;
let $ = window.$;

document.addEventListener('DOMContentLoaded', function() {
    //Temp
    $('#header-cart').on('click', () => {
        window.location.href = '/cart';
    });

    $('#header-wishlist').on('click', () => {
        window.location.href = '/wishlist';
    });

    $('#header-account').on('click', (e) => {
        window.location.href = $(e.currentTarget).data('link');
    });

    $('#button-toggle-menu').on('click', () => {
        $('#mobile-menu').addClass('show');
        $('body').addClass('dark');
    });

    initLanguageForms();
    initSearch();
});

/*
$('.close-mb-menu').on('click', () => {
    $('#mobile-menu').removeClass('show');
    $('body').removeClass('dark');
});*/