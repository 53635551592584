const fieldFocus = function(){
    const offcanvasSearch = document.getElementById('offcanvasSearch');
    offcanvasSearch.addEventListener('show.bs.offcanvas', e => {
        
        setTimeout(() => {
            e.target.querySelector('input[type=text]').focus();
        }, 0);
    });
};

const initSearch = function(){
    fieldFocus();
};

export default initSearch;
// TODO: Move all search related code here!